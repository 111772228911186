import {Fragment, useEffect, useState} from 'react';
import RouterApp from './router.react';

export default function App() {
    const [Loading, SetLoading] = useState(true);

    useEffect(() => {
        async function init() {
            try {
                SetLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        init();
    }, []);

    if (Loading) {
        return null;
    }

    return (
        <Fragment>
            <RouterApp />
        </Fragment>
    );
}
