export class FetchResponseError<
    Payload extends {status_code: number} = {status_code: number}
> extends Error {
    payload?: Payload;

    constructor(message: string, payload?: Payload) {
        super(message);
        this.payload = payload;
        this.name = 'FetchResponseError';
    }
}

export interface RequestBase {
    url: string;
    server?: 'golang' | 'php';
    remote?: boolean;
    headers?: Record<string, any>;
    query?: Record<string, any>;
    body?: Record<string, any>;
}

export interface Request extends RequestBase {
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' | 'PATCH';
}

export type ResultWithError<R> = [R, null] | [null, FetchResponseError];
