import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import path from 'path';
import {FetchResponseError, Request, RequestBase, ResultWithError} from './model';
import * as runtypes from 'runtypes';
import CheckResultRuntime from './CheckResultRuntime';

export type {ResultWithError};
export {FetchResponseError};

export async function get<R = unknown>(
    cfg: RequestBase,
    runtype?: runtypes.Runtype<R>
): Promise<ResultWithError<R>> {
    const res = await MakeCall<R>({
        ...cfg,
        method: 'GET',
    });

    return runtype ? CheckResultRuntime(runtype, res) : res;
}

export async function post<R = unknown>(
    cfg: RequestBase,
    runtype?: runtypes.Runtype<R>
): Promise<ResultWithError<R>> {
    const res = await MakeCall<R>({
        ...cfg,
        method: 'POST',
    });

    return runtype ? CheckResultRuntime(runtype, res) : res;
}

export async function put<R = unknown>(
    cfg: RequestBase,
    runtype?: runtypes.Runtype<R>
): Promise<ResultWithError<R>> {
    const res = await MakeCall<R>({
        ...cfg,
        method: 'PUT',
    });

    return runtype ? CheckResultRuntime(runtype, res) : res;
}

export async function del<R = unknown>(
    cfg: RequestBase,
    runtype?: runtypes.Runtype<R>
): Promise<ResultWithError<R>> {
    const res = await MakeCall<R>({
        ...cfg,
        method: 'DELETE',
    });

    return runtype ? CheckResultRuntime(runtype, res) : res;
}

async function MakeCall<R = unknown>(cfg: Request): Promise<ResultWithError<R>> {
    const url = cfg.remote
        ? cfg.url
        : `${document.location.origin}${path.join(
              `/api/v${cfg.server === 'php' ? 3 : 1}/`,
              cfg.url
          )}`;
    let headers: Record<string, any> = {};

    if (cfg.headers) {
        headers = {...headers, ...cfg.headers};
    }

    if (!cfg.query) {
        cfg.query = {};
    }

    const config: AxiosRequestConfig = {
        method: cfg.method,
        url,
        headers,
        params: cfg.query,
        data: cfg.body,
        validateStatus: status => status >= 200 && status < 300,
    };

    try {
        const res = await axios.request<R>(config);
        return CreateResponse<R>(res);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (
                typeof error.response?.headers['content-type'] === 'string' &&
                error.response?.headers['content-type'].toLowerCase() === 'application/json'
            ) {
                const msg = error.response.data?.status_text || 'Server response error';
                return [null, new FetchResponseError(msg, error.response.data)];
            } else {
                return [null, new Error(error.message)];
            }
        } else {
            return [null, new Error(error instanceof Error ? error.message : 'Unknown error')];
        }
    }
}

function CreateResponse<R = unknown>(res: AxiosResponse<R>): ResultWithError<R> {
    if (
        typeof res.headers['content-type'] != 'string' ||
        res.headers['content-type'].toLowerCase() !== 'application/json'
    ) {
        return [null, new FetchResponseError('response is not json')];
    }

    return [res.data, null];
}

export type GetListParams = {
    order_by?: string;
    order_dir?: 'ASC' | 'DESC';
    page_id?: number;
    page_size?: number;
    q?: string;
    from?: number;
    to?: number;
};
