/* Use lazy loading for huge pages please, just wrap your page into React.Suspense */
/* keep in mind that pages w/ lazy loading may brake page switch animation */
/* maybe we need a flap on page level to which would be use to enable page switch animation */
import React, {ComponentType, ReactNode, lazy} from 'react';
import SafeUpdate from 'immutability-helper';
import {matchPath, RedirectProps} from 'react-router-dom';
import * as runtypes from 'runtypes';
import Page404React from 'src/jsx/System/404page.react';

const Landing = lazy(() => import('src/jsx/Landing/Landing'));

export const EmptyMeta = runtypes.Null;

export type PageProps<T = null> = {
    id: string;
    childRoutes: Page[];
    children?: ReactNode;
    meta: {
        is_loading: boolean;
        is_error: boolean;
        payload: T;
        redirect_source?: string;
    };
};

export const pages: Page<any>[] = [];
export const redirects: RedirectProps[] = [];

export const Page404: Page = {
    id: '404error',
    path: '',
    exact: true,
    Component: Page404React,
    meta: EmptyMeta,
};

/* KEEP IN MIND THAT PAGE ADDED JUST HERE W/O Go HANDLER WILL BE SOFT 404 */
/* meta should be used to pass only very important and small amount of data e.g. access error otherwise page loading will be slow */
export const RoutesObject = {
    landing: {path: ['', ':uri'], exact: true, Component: Landing, meta: EmptyMeta},
};

type PageEntry = Omit<Page, 'id' | 'subRoutes'> & {
    subRoutes?: Record<string, PageEntry>;
};

function MakePage(key: PageID, obj: PageEntry): Page {
    let page: Page = {...obj, id: key, subRoutes: []};
    if (obj.subRoutes) {
        page.subRoutes = Object.entries(obj.subRoutes).map(entry =>
            MakePage(entry[0] as PageID, entry[1])
        );
    }

    return page;
}

const Routes: Page[] = Object.entries(RoutesObject).map(entry =>
    MakePage(entry[0] as PageID, entry[1])
);
AddRoutes(Routes);

export type PageID = '404error' | keyof typeof RoutesObject;

export interface Page<T = null> {
    id: PageID;
    parent_id?: string;
    path: string | string[];
    exact: boolean;
    Component: ComponentType<PageProps<T>> | React.LazyExoticComponent<() => JSX.Element>;
    meta: runtypes.Runtype;
    subRoutes?: Page<T>[];
    name?: string;
}

export function AddRoutes<T = null>(routes: Page<T>[], parent?: Page<T>) {
    routes.forEach(route => {
        if (parent) {
            route = SafeUpdate(route, {parent_id: {$set: parent.id}});
            route = SafeUpdate(route, {path: {$set: `${parent.path}/${route.path}`}});
        }

        pages.push(route);
        if (route.subRoutes?.length) {
            AddRoutes<T>(route.subRoutes, route);
        }
    });
}

export function FindPage(Pages: Page[], path: string) {
    return Pages.find(page => {
        return matchPath(path, {
            path: typeof page.path === 'string' ? `/${page.path}` : page.path.map(p => `/${p}`),
            exact: page.exact,
        });
    });
}
