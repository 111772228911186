import { createBrowserHistory, Location } from 'history';
import GA4React from 'ga-4-react';
//import { TrackPageView } from './tracking';
export const history = createBrowserHistory();

const ga4react = new GA4React('G-9B0J956ESN');

const trackGA = (loc: any) => {
    if (loc.pathname.indexOf('/manager') === -1) {
        if (GA4React.isInitialized()) {
            ga4react.pageview(loc.pathname + loc.search);
        }
    }
};

const initGA = async () => {
    await ga4react.initialize();
    trackGA(window.location);
};
initGA();

history.listen((location) => {
    OnHistoryChange(location);
});

export function OnHistoryChange(location: Location, init?: boolean) {
    trackGA(location);
}

export const OpenPage = (path: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(`/${path}`);
    window.scrollTo(0, 0);
}
